body {
  background: url(../public/images/background.svg) no-repeat bottom right fixed;
    height: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
}

div#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.version {
  color: #a4a4a4;
  font-size: 8pt;
}

.trpoint tr,
.cpointer {
  cursor: pointer;
}

.icons-stack-16x16 img {
  width: 16px;
  height: 16px;
  margin-left: 3px;
}

.main-menu img {
  width: 24px;
}

.details-table th {
  font-weight: bold;
  width: 200px;
  padding-left: 0;
}

.legend-green {
  color: #22a569 !important
}

.legend-orange {
  color: #fca130 !important
}

.legend-red {
  color: #f93e3e !important
}

.help {
  cursor: help;
}

.icons-16x16 {
  width: 16px;
  height: 16px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  border: 1px solid #757575;
  box-shadow: 24;
  padding: 10px;
  border-radius: 4px;
}

a {
  color:#3982cb;
  text-decoration: none;
}